dmx.Component('date-picker', {

  extends: 'form-element',

  attributes: {
    name: {
      type: String,
      default: '',
    },

    showdropdowns: {
      type: Boolean,
      default: false,
    },

    minyear: {
      type: Number,
      default: undefined,
    },

    maxyear: {
      type: Number,
      default: undefined,
    },
	
	  autoapply: {
      type: Boolean,
      default: true,
    },
	
    opens: {
      type: String,
      default: 'right',
      enum: ['left', 'right', 'center'],
    },

    drops: {
      type: String,
      default: 'down',
      enum: ['down', 'up', 'auto'],
    },

    dropsup: {
      type: Boolean,
      default: false,
    },

    showweeknumbers: {
      type: Boolean,
      default: false,
    },

    mindate: {
      type: String,
      default: '',
    },

    maxdate: {
      type: String,
      default: '',
    },

    format: {
      type: String,
      default: null,
    },

    invaliddates: {
      type: Array,
      default: [],
    },

    invaliddatesStart: {
      type: String,
      default: 'start',
    },

    invaliddatesEnd: {
      type: String,
      default: 'end',
    },

    customdates: {
      type: Array,
      default: [],
    },

    customdatesStart: {
      type: String,
      default: 'start',
    },

    customdatesEnd: {
      type: String,
      default: 'end',
    },

    customdatesClass: {
      type: String,
      default: 'class',
    },

    disableweekends: {
      type: Boolean,
      default: false,
    },

    direction: {
      type: String,
      default: 'ltr',
      enum: ['ltr', 'rtl'],
    },

    weeklabel: {
      type: String,
      default: 'W',
    },

    applylabel: {
      type: String,
      default: 'Apply',
    },

    cancellabel: {
      type: String,
      default: 'Cancel',
    },

    timepicker: {
      type: Boolean,
      default: false,
    },

    use24hours: {
      type: Boolean,
      default: false,
    },

    minutesIncrement: {
      type: Number,
      default: 1,
    },

    utc: {
      type: Boolean,
      default: false,
    },
  },

  events: {
    show: Event,
    hide: Event,
    apply: Event,
    cancel: Event,
  },

  init (node) {
    this._isCustomDate = this._isCustomDate.bind(this);
    this._isInvalidDate = this._isInvalidDate.bind(this);
    this._updateValue = this._updateValue.bind(this);
    this._applyHandler = this._applyHandler.bind(this);
    this._changeHandler = this._changeHandler.bind(this);

    dmx.Component('form-element').prototype.init.call(this, node);

    if (!this.props.format) {
      this.props.format = this.props.timepicker ? 'L LT' : 'L';
    }

    this._createHiddenInput();

    node.removeAttribute('name');
    node.autocomplete = 'off';
    node.value = this._formatDate(this.props.value) || '';
    node.defaultValue = node.value;
   
    this._createDaterangePicker();
  },

  destroy () {
    if (this._daterangepicker) {
      this._daterangepicker.remove();
    }

    if (this._input) {
      this._input.remove();
    }
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('name')) {
      this._input.name = this.props.name;
      if (updatedProps.size === 1) return;
    }

    if (updatedProps.has('disabled')) {
      this.$node.disabled = this.props.disabled;
      this._input.disabled = this.props.disabled;
      if (updatedProps.size === 1) return;
    }

    if (updatedProps.has('value')) {
      this._setValue(this.props.value, true);
      if (updatedProps.size === 1) return;
    }

    if (!this.props.format) {
      this.props.format = this.props.timepicker ? 'L LT' : 'L';
    }

    this._createDaterangePicker();
  },

  _createHiddenInput () {
    this._input = document.createElement('input');
    this._input.type = 'hidden';
    this._input.name = this.$node.name;
    this._input.value = this.props.value;
    this._input.defaultValue = this.props.value;
    this.$node.parentNode.insertBefore(this._input, this.$node);
  },

  _createDaterangePicker () {
    const $node = $(this.$node);

    $node.daterangepicker({
      parentEl: $node.closest('.modal, .offcanvas, .offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl')[0] || document.body,
      singleDatePicker: true,
      autoUpdateInput: false,
	    autoApply: this.props.autoapply,
      showWeekNumbers: this.props.showweeknumbers,
      showDropdowns: this.props.showdropdowns,
      minYear: this.props.minyear,
      maxYear: this.props.maxyear,
      opens: this.props.opens,
      drops: this.props.dropsup ? 'up' : this.props.drops,
      minDate: this._formatDate(this.props.mindate),
      maxDate: this._formatDate(this.props.maxdate),
      locale: {
        format: this.props.format,
        direction: this.props.direction,
        weekLabel: this.props.weeklabel,
        applyLabel: this.props.applylabel,
        cancelLabel: this.props.cancellabel,
      },
      buttonClasses: '',
      applyButtonClasses: '',
      cancelButtonClasses: '',
      isCustomDate: this._isCustomDate,
      isInvalidDate: this._isInvalidDate,
      timePicker: this.props.timepicker,
      timePicker24Hour: this.props.use24hours,
      timePickerIncrement: this.props.minutesIncrement,
    }, this._updateValue);

    $node.on('apply.daterangepicker', this._applyHandler);
    $node.on('change.daterangepicker', this._changeHandler);

    $node.on('show.daterangepicker', this.dispatchEvent.bind(this, 'show'));
    $node.on('hide.daterangepicker', this.dispatchEvent.bind(this, 'hide'));
    $node.on('apply.daterangepicker', this.dispatchEvent.bind(this, 'apply'));
    $node.on('cancel.daterangepicker', this.dispatchEvent.bind(this, 'cancel'));

    this._daterangepicker = $node.data('daterangepicker');
  },

  _formatDate (str) {
    if (!str) return undefined;
    if (str == 'now' || str == 'today') return moment().format(this.props.format);
    const date = moment(str);
    return date.isValid() ? date.format(this.props.format) : undefined;
  },

  _isInvalidDate (date) {
    if (this.props.disableweekends && (date.day() === 0 || date.day() === 6)) return true;
    return this.props.invaliddates.some(range => this._isInRange(date, range, this.props.invaliddatesStart, this.props.invaliddatesEnd));
  },

  _isCustomDate (date) {
    return this.props.customdates.filter(range => this._isInRange(date, range, this.props.customdatesStart, this.props.customdatesEnd)).map(range => range[this.props.customdatesClass]);
  },  

  _isInRange (date, range, start, end) {
    if (range[start] && range[end]) return date.isSameOrAfter(range[start]) && date.isSameOrBefore(range[end]);
    if (range[start]) return date.isSameOrAfter(range[start]);
    if (range[end]) return date.isSameOrBefore(range[end]);
    return false;
  },

  _updateValue (date) {
    let value = this.props.timepicker ? this.props.utc ? date.toISOString() : date.format('YYYY-MM-DD HH:mm:ss') : date.format('YYYY-MM-DD');
    let prevValue = this.data.value;
    this._setValue(value);

    if (prevValue !== this.data.value) {
      this.dispatchEvent('change');
      this.dispatchEvent('changed');
    }
  },

  _updateData (event) {
    if (event && this.$node.dirty) {
      dmx.validate(this.$node);
    }

    this.set('disabled', this.$node.disabled);
    if (this._input.value !== this.data.value) {
      this.set('value', this._input.value);
      dmx.nextTick(() => this.dispatchEvent("updated"));
    }

    if (this.$node.dirty) {
      this.set('invalid', !this.$node.validity.valid);
      this.set('validationMessage', this.$node.validationMessage);
    }
  },

  _setValue (value, defaultValue) {
    if (typeof value !== 'string') value = '';
    
    if (value == 'now' || value == 'today') {
      if (this.props.timepicker) {
        value = this.props.utc ? moment().toISOString() : moment().format('YYYY-MM-DD HH:mm:ss');
      } else {
        value = moment().format('YYYY-MM-DD');
      }
    }

    this._daterangepicker.setStartDate(this._formatDate(value) || new Date());
    this._daterangepicker.setEndDate(this._formatDate(value) || new Date());

    this.$node.value = this._formatDate(value) || '';
    this._input.value = value || '';

    if (defaultValue) {
      this.$node.defaultValue = this.$node.value;
      this._input.defaultValue = this._input.value;
    }  

    this._updateData(true);
  },

  _applyHandler (event) {
    this._updateValue(this._daterangepicker.startDate);
  },

  _changeHandler (event) {
    if (!this.$node.value || !moment(this.$node.value, this.props.format).isValid()) {
      this._setValue('');
    } else {
      if (this.props.timepicker) {
        if (this.props.utc) {
          this._setValue(moment(this.$node.value, this.props.format).toISOString());
        } else {
          this._setValue(moment(this.$node.value, this.props.format).format('YYYY-MM-DD HH:mm:ss'));
        }
      } else {
        this._setValue(moment(this.$node.value, this.props.format).format('YYYY-MM-DD'));
      }
    }
  },

  _resetHandler (event) {
    if (!this.$node) return;
    this.$node.dirty = false;
    this.set({
      invalid: false,
      validationMessage: '',
    });
    dmx.nextTick(() => {
      if (!this.$node) return;
      this._changeHandler()
    });
  },

});
